class PaymentServiceImpl {
  constructor(token) {
    this.token = token || '';
  }

  payOrder(id, paymentAdapter, onSuccess, onError) {
    console.debug('orderId', id);
    console.debug('adapter', paymentAdapter);

    /*global PaymentsClient */
    /*eslint no-undef: "error"*/
    // @ts-ignore: Imported in main app
    var payments = new PaymentsClient(
      null,
      this.token,
      window.parametrize('VUE_APP_GRAPHQL_ENDPOINT'),
      'null',
      false,
      false
    );

    (dataLayer = window.dataLayer || []).push({
      'eCategory': 'dreams_step4_paymentscreen',
      'eAction': 'viewScreen',
      'event': 'GTMView'
    });

    payments.pay(
      id,
      paymentAdapter,
      () => {
        onSuccess();
      },
      (e) => {
        onError(e.data);
      },
      null,
      null
    );
  }
}

export default PaymentServiceImpl;
