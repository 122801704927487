<template>
  <a-layout-header class="header">
    <span class="step__title">
      <span class="step__title__main">{{ steps[current].title }}</span>
      <span v-if="current === 1" class="step__title__additional">
        (можно выбирать не более
        {{ orderStore.salesLimit }}
        {{ pluralize(orderStore.salesLimit, ['билет', 'билета', 'билетов']) }})
      </span>
    </span>
    <span v-if="current < 3" class="step__counter">Шаг {{ current + 1 }} из {{ steps.length }}</span>
    <svg v-if="current === 3" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <path
        d="M36.7234 18.3096C36.8141 18.4046 36.8852 18.5165 36.9326 18.6389C36.98 18.7614 37.0028 18.892 36.9998 19.0233C36.9967 19.1546 36.9678 19.284 36.9148 19.4041C36.8617 19.5242 36.7854 19.6327 36.6904 19.7234L22.0239 33.7234C21.8379 33.9009 21.5907 34 21.3335 34C21.0764 34 20.8291 33.9009 20.6431 33.7234L13.3096 26.7234C13.1189 26.54 13.0087 26.2885 13.0031 26.024C12.9974 25.7596 13.0968 25.5036 13.2794 25.3122C13.4621 25.1209 13.7131 25.0097 13.9776 25.003C14.242 24.9963 14.4984 25.0947 14.6904 25.2766L21.3335 31.6175L35.3096 18.2766C35.4046 18.1859 35.5165 18.1148 35.639 18.0674C35.7614 18.02 35.892 17.9972 36.0233 18.0002C36.1546 18.0033 36.284 18.0322 36.4041 18.0852C36.5242 18.1383 36.6327 18.2146 36.7234 18.3096ZM50 25C50 29.9445 48.5338 34.778 45.7867 38.8892C43.0397 43.0005 39.1352 46.2048 34.5671 48.097C29.9989 49.9892 24.9723 50.4842 20.1227 49.5196C15.2732 48.555 10.8187 46.174 7.32234 42.6777C3.82603 39.1813 1.44501 34.7268 0.480379 29.8773C-0.484251 25.0277 0.0108321 20.0011 1.90302 15.4329C3.79521 10.8648 6.99953 6.96029 11.1108 4.21326C15.222 1.46622 20.0555 0 25 0C31.6281 0.0074771 37.9826 2.6438 42.6694 7.33059C47.3562 12.0174 49.9925 18.3719 50 25ZM48 25C48 20.451 46.6511 16.0042 44.1238 12.2219C41.5965 8.43955 38.0044 5.49159 33.8017 3.75077C29.599 2.00995 24.9745 1.55448 20.5129 2.44194C16.0514 3.3294 11.9532 5.51993 8.73655 8.73654C5.51995 11.9531 3.32941 16.0514 2.44195 20.5129C1.55449 24.9745 2.00997 29.599 3.75078 33.8017C5.4916 38.0044 8.43956 41.5965 12.2219 44.1238C16.0042 46.6511 20.451 48 25 48C31.0979 47.9931 36.944 45.5676 41.2558 41.2558C45.5677 36.944 47.9931 31.0979 48 25Z"
        fill="#21A038"
      />
    </svg>
  </a-layout-header>
  <a-layout-content class="content">
    <Tabs class="tabs" v-if="current === 0" @next="next" :activeKey="activeKey"/>
    <ChooseTickets class="ticket" v-if="current === 1"/>
    <PaymentMethods class="payment" v-if="current === 2"/>
    <Finish class="finish" v-if="current === 3"/>
  </a-layout-content>
  <a-layout-footer class="footer">
    <div class="footer__nav">
      <a-button
        class="button__prev"
        v-if="showBtnPrev()"
        style="margin-right: auto"
        @click="prev">Назад
      </a-button>
      <a-button class="button__prev__mobile" v-if="showBtnPrev()" style="margin-right: auto" @click="prev">
        <template #icon>
          <LeftOutlined/>
        </template>
      </a-button>
      <div v-if="current == 1 && orderStore.getTotalQuantity > 0" class="discount__wrapper">
        <div class="discount__info">
          <div class="discount__value">
            Скидка по карте Сбера
            {{ sberDiscount }}%:
          </div>
          <p class="discount__price">{{ pricesStore.getDiscountTotalPrice }} ₽</p>
        </div>
      </div>
      <a-button
        v-if="current == 1 && orderStore.getTotalQuantity > 0"
        @click="next"
        class="primary__border"
        type="primary"
        :disabled="!userStore.sberSoundChecked"
      >
        <p>
          {{ orderStore.paymentMethod === PaymentsAdapters.SBER ? pricesStore.getDiscountTotalPrice : pricesStore.getTotalPrice }} ₽
        </p>
        <p>
          {{ orderStore.getTotalQuantity }}
          {{ pluralize(orderStore.getTotalQuantity, ['билет', 'билета', 'билетов']) }}
        </p>
      </a-button>
      <a-button
        v-if="submitBtnState()"
        @click="submitForm"
        class="primary__border payment_btn"
        type="primary"
        :disabled="!userStore.isFormValid"
        :loading="orderLoading"
      >
        <div class="button_text_wrapper">
          <p>
            {{ orderStore.paymentMethod === PaymentsAdapters.SBER ? pricesStore.getDiscountTotalPrice : pricesStore.getTotalPrice }} ₽
          </p>
          <p>
            {{ orderStore.getTotalQuantity }}
            {{ pluralize(orderStore.getTotalQuantity, ['билет', 'билета', 'билетов']) }}
          </p>
        </div>
      </a-button>
      <a-button v-if="current == 3" @click="toHomeScreen" class="primary__border home_btn" type="primary">
        <p>На главный</p>
        <p>экран</p>
      </a-button>
    </div>
    <div class="sub-info" v-if="current === 2 && userStore.isFormValid">
      <div class="sub-info__first">
        <span class="sub-info__text">Нажимая кнопку “Оплаты”, вы соглашаетесь на условие договора оферты. </span>
        <a v-if="ofertaLink" :href="ofertaLink" target="_blank" class="sub-info__link">Договор оферты</a>
      </div>
      <a v-if="requisitesLink" :href="requisitesLink" target="_blank" class="sub-info__link__requisites">
        Реквизиты компании и описание процесса оплаты.
      </a>
    </div>
    <div v-if="!eventsStore.loading && !pricesStore.loading" class="fanzilla-logo"></div>
  </a-layout-footer>

  <a-modal v-if="current == 1" v-model:open="infoModalOpen" :footer="null" class="restriction-modal">
    <template #title>
      <span class="restriction-modal__heading">Добавьте Взрослый билет</span>
    </template>
    <p class="restriction-modal__text">{{ ageRestrictionMessage }}</p>
  </a-modal>
</template>

<script setup>
import Tabs from '../components/Tabs.vue';
import PaymentMethods from '../components/PaymentMethods.vue';
import ChooseTickets from '../components/ChooseTickets.vue';
import Finish from '../components/Finish.vue';
import {computed, ref, watch} from 'vue';
import {message} from 'ant-design-vue';
import {LeftOutlined} from '@ant-design/icons-vue';
import {useTicketsStore} from '@/store/TicketsStore';
import {useUserStore} from '@/store/UserStore';
import {useEventsStore} from '@/store/EventsStore';
import {useOrderStore} from "@/store/OrderStore";
import {usePricesStore} from "@/store/PricesStore";
import {ageRestrictionMessage, sberDiscount} from "@/constants";
import {pluralize} from "@/utils/formatters";
import {useAuthStore} from "@/store/AuthStore";
import PaymentServiceImpl from "@/services/PaymentService";
import {PaymentsAdapters} from "../utils/constants";

const ofertaLink = window.parametrize('VUE_APP_OFERTA_LINK');
const requisitesLink = window.parametrize('VUE_APP_REQUISITES_LINK');

const ticketsStore = useTicketsStore();
const userStore = useUserStore();
const eventsStore = useEventsStore();
const orderStore = useOrderStore();
const pricesStore = usePricesStore();
const authStore = useAuthStore();
const orderLoading = ref(false);
const infoModalOpen = ref(false);

const steps = [
  {
    title: 'Расписание',
    content: 'шаг 1',
  },
  {
    title: 'Выберите билет',
    content: 'шаг 2',
  },
  {
    title: 'Оформление заказа',
    content: 'шаг 3',
  },
  {
    title: 'Заказ успешно оплачен',
    content: 'шаг 4',
  },
];

const activeKey = ref(eventsStore.currentMonth);

watch(() => eventsStore.loading, () => {
  activeKey.value = eventsStore.currentMonth;
})

const current = ref(0);
const setItems = () => {
  return (items = this.steps.map((item) => ({
    key: item.title,
    title: item.title,
  })));
};

const next = (session) => {
  switch (current.value) {
    case 0:
      pricesStore.fetchPlacePrices(session.id);
      orderStore.setCurrentSession(session)
      current.value++; // Используйте this.current.value для изменения значения current
      break;
    case 1:
      const validity = orderStore.validateCart();
      if (validity) {
        current.value++;
      } else {
        infoModalOpen.value = true;
      }
      break;
    case 2:
      current.value++;
      // TODO: обновление данных пользователя, reserveByCategory, вызов оплаты
      break;
    default:
      current.value++;
  }

};

const prev = () => {
  switch (current.value) {
    case 1:
      current.value--;
      pricesStore.pricesReset()
      orderStore.orderReset()
      break;
    case 2:
      current.value--;
      orderStore.setPaymentMethod(PaymentsAdapters.OTHER);
      break;
    default:
      current.value--;
  }

};
const isValid = computed(() => userStore.isFormValid);

const metric = (orderId, revenue, productsRes, paymentMethod) => {
  (dataLayer = window.dataLayer || []).push({
    'ecommerce': {
      'purchase': {
        'actionField': {
          'id': orderId,   //номер заказа
          'revenue': revenue.toString(),   //полная сумма заказа, с учетом всех билетов (number)
        },
        'products': productsRes
      }
    },
    'payment': paymentMethod, // Выбранный способ опаты
    'eCategory': 'eEC',
    'eAction': 'purchase',
    'eLabel': '',
    'eNI': false,
    'event': 'GTMPurchase'
  });
}

const parseTargetData = () => {
  const revenue = orderStore.paymentMethod === PaymentsAdapters.SBER ? pricesStore.getDiscountTotalPrice : pricesStore.getTotalPrice;

  const products = orderStore.currentOrder.items.reduce((acc, ticket) => {
    const priceCategoryId = ticket.item.priceCategory.id;

    const existingProduct = acc.find(product => product.priceCategory.id === priceCategoryId);
    if (existingProduct) {
      return acc;
    }

    acc.push({
      name: ticket.item.priceCategory.title,
      price: pricesStore.getPriceByCategoryId(priceCategoryId).toString(),
      quantity: orderStore.getAmountByCategoryId(priceCategoryId),
      priceCategory: {
        id: priceCategoryId
      }
    });

    return acc;
  }, [])

  const productsRes = products.map(product => ({
    name: product.name,
    price: product.price,
    quantity: product.quantity
  }))

  metric(
    orderStore.orderId,
    revenue,
    productsRes,
    orderStore.paymentMethod
  )
}

const initPayment = async (onDone, onFail) => {
  const paymentService = new PaymentServiceImpl(authStore.getToken());
  const onSuccess = () => {
    message.success('Оплата прошла успешно');
    parseTargetData();
    onDone ? onDone() : null;
  }
  const onError = (e) => {
    if (e.source !== 'provider') {
      console.error(e);
      message.error('Произошла ошибка при оплате');
      onFail ? onFail() : null;
    }
  }
  await paymentService.payOrder(orderStore.orderId, orderStore.paymentMethod, onSuccess, onError);
}

const submitForm = async () => {
  (dataLayer = window.dataLayer || []).push({
    'eCategory': 'dreams_payOrder',
    'eAction': 'clickButton',
    'event': 'GTMButtons'
  });
  orderLoading.value = true;
  await orderStore.getCurrentOnly();
  if (orderStore.currentOrder) {
    await orderStore.cancelOrder();
  }
  await userStore.updateProfile()
    .then(() => {
      orderStore.reserveByCategory(toHomeScreen)
        .then(async () => {
          orderStore.setPaymentInProcess(true);
          await initPayment(() => {
              orderStore.setPaymentInProcess(false);
              current.value += 1;
            },
            () => {
              orderStore.setPaymentInProcess(false);
              orderLoading.value = false;
            }
          );
        })
        .catch((error) => {
          console.error(error);
          const noLongerAvailableErrors = ['7d739e78', 'd7f581ce'];
          const errorUid = error.graphQLErrors[0]?.extensions?.uid || '';
          if (errorUid && !noLongerAvailableErrors.includes(error.graphQLErrors[0].extensions.uid || '')) {
            message.error('Произошла ошибка при бронировании, попробуйте еще раз');
          } else if (!errorUid) {
            message.error('Произошла ошибка при бронировании, попробуйте еще раз');
          }
        })
    })
    .catch((error) => {
      orderStore.setPaymentInProcess(false);
      message.error('Произошла ошибка при обновлении профиля');
      orderLoading.value = false;
    })
};
const submitBtnState = () => {
  return current.value > 1 && current.value < steps.length - 1;
};
const showBtnPrev = () => {
  return current.value > 0 && current.value !== 3 && !pricesStore.loading;
};

const toHomeScreen = () => {
  current.value = 0;
  orderStore.setPaymentMethod(PaymentsAdapters.OTHER);
  pricesStore.pricesReset();
  orderStore.orderReset();
}
</script>

<style lang="stylus" scoped>
.step__title__additional {
  font-size: var(--fz-12);
  font-weight: var(--fz-400);
  line-height: 1;
}

.discount__info_type_disabled
  cursor default
  opacity 0.5

.discount__value {
  display: flex;
  align-items: center;
}

.discount__logo {
  width: 72px;
  height: 22px;
  background-image: url('@/assets/sberLogoWithText.svg');
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 480px) {
    width: 58px;
    height: 16px;
  }
}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;

  &__nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 26px;
  }
}

.payment_btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.payment_btn:disabled {
  border: none;
}

.button_text_wrapper {
  display: inline-block;
}

.home_btn {
  font-size: 16px;
  line-height: 1.35;
  margin-left: auto;
}

.sub-info {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: var(--fz-10);
  font-weight: var(--font-500);

  &__first {
    width: 100%;
  }

  &__text {
    color: var(--color-grey-middle);
  }

  &__link {
    &__requisites {
      width: 100%;
    }
  }
}

.fanzilla-logo {
  background: url("@/assets/fanzilla.svg") no-repeat;
  width: 99px;
  height: 21px;
  margin-right: auto;
  margin-top: 26px;
}

.restriction-modal {
  &__heading {
    font-family: var(--font-family);
    font-size: var(--fz-24);
    font-weight: 500;
  }

  &__text {
    color: rgba(0, 0, 0, 0.50);
    font-size: var(--fz-14);
  }
}
</style>
