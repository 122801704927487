export const sberDiscount = 15;

export const ageRestrictionMessage = "Дети до 14 лет допускаются к катанию на катке только в сопровождении взрослых (лиц старше 18 лет).";

  export const priceCategoryDescriptions = new Map([
  ["Льготный", [
    {text: "При выборе любого льготного билета, возьмите документ, подтверждающий эту льготу.", highlight: false}
  ]],
  ["Льготники", [
    {text: "При выборе любого льготного билета, возьмите документ, подтверждающий эту льготу.", highlight: false}
  ]],
  ["Льготный + коньки", [
    {text: "При выборе любого льготного билета, возьмите документ, подтверждающий эту льготу.", highlight: false}
  ]],
  ["Детский", [
    {text: "Детский билет может применяться для детей от 3-х до 14 лет.", highlight: false},
    {
      text: ageRestrictionMessage,
      highlight: true
    },
    {text: "При себе обязательно иметь свидетельство о рождении.", highlight: false},
  ]],
  ["Детский + коньки", [
    {text: "Детский билет может применяться для детей от 3-х до 14 лет.", highlight: false},
    {
      text: ageRestrictionMessage,
      highlight: true
    },
    {text: "При себе обязательно иметь свидетельство о рождении.", highlight: false},
  ]],
])
